<template>
  <Modal class="move-modal" :mask-closable="false" v-model="visible" width="484" @on-cancel="close">
    <div style="text-align:center">
      <h2>移动员工</h2>
    </div>
    <div>
      <div id="modal-tree-container" class="container">
        <div class="container-title">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-jiagoufenxiao" />
          </svg>
          <span style="margin-left: 8px;">{{ name }}</span>
        </div>
        <Tree id="modal-tree" :data="treeData" @on-select-change="select" />
      </div>
    </div>
    <div slot="footer">
      <Button @click="close" class="modal-btn">取消</Button>
      <Button type="primary" @click="confirm" class="modal-btn" :loading="loading">确定</Button>
    </div>
  </Modal>
</template>

<script>
import departmentApi from '@api/department'
import staff from '@api/staff'
export default {
  name: 'MoveStaffsModal',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    name: {
      type: String
    },
    treeData: {
      type: Array
    },
    userIds: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  data: function() {
    return {
      visible: this.value,
      loading: false,
      dId: ''
    }
  },
  methods: {
    select(val) {
      this.dId = val[0].detail.departmentId
    },
    close() {
      this.visible = false
      this.$emit('input', false)
      this.$emit('on-cancel')
    },
    confirm() {
      if (!this.dId) {
        return this.$message({ message: '请选择移动的组织', type: 'warning' })
      }
      this.loading = true
      const payload = {
        desDepartmentId: this.dId,
        userIds: this.userIds
      }
      staff.moveStaff(payload).then(res => {
        if (res.res === true) {
          this.$message.success('移动成功')
          this.$emit('moveSuccess')
          this.close()
        } else {
        }
        this.loading = false
      })
    }
  },
  watch: {
    value(val) {
      this.visible = val
    }
  }
}
</script>

<style lang="less" scoped>
#modal-tree-container {
  height: 400px;
  overflow-y: scroll;
  text-align: left;
}

.container-title {
  width: 100%;
  background: rgba(228, 233, 239, 1);
  padding: 10px;
  margin-top: 20px;
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  color: rgba(58, 78, 100, 1);
  cursor: pointer;
}

#modal-tree {
  padding: 5px 20px;
}

.move-modal ::v-deep .ivu-modal {
  .ivu-modal-footer {
    .modal-btn:first-child {
      margin-right: 5px;
    }

    .modal-btn {
      .ivu-icon {
        font-size: 14px;
      }
    }
  }
}
</style>
