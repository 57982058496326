<template>
  <div class="all-container" style="text-align: left">
    <Breadcrumb class="custom-breadcrumb">
      <BreadcrumbItem>人员管理</BreadcrumbItem>
      <BreadcrumbItem>组织架构</BreadcrumbItem>
    </Breadcrumb>
    <div id="top-filter">
      <div id="top-tab" class="container">
        <div :class="`${isDeleteTab ? '' : 'top-tab-active-item'} top-tab-item`" @click="clickTopTab(0)">在职员工({{ countAllStaff }})</div>
        <div style="width:2px; background:#5C6D81;margin: 13px 5px;"></div>
        <div :class="`${isDeleteTab ? 'top-tab-active-item' : ''} top-tab-item`" @click="clickTopTab(1)">已删除员工({{ pageParamForm.total }})</div>
      </div>
      <div class="top-button-list container" v-if="isDeleteTab" :style="{ 'margin-top': isDeleteTab ? '10px' : '' }">
        <div>
          <Button
            class="filter-btn"
            icon="ios-redo-outline"
            type="primary"
            @click="
              showRevertConfirm = true
              isRestoreBatch = true
            "
            :disabled="!this.checkedDeletedTableData.length"
          >
            恢复员工
          </Button>
          <Button
            class="filter-btn"
            icon="ios-trash-outline"
            type="primary"
            @click="
              showDeleteFinallyConfirm = true
              isDeleteFinallyBatch = true
            "
            :disabled="!this.checkedDeletedTableData.length"
            >彻底删除
          </Button>
        </div>
        <div class="search-container">
          <Input search placeholder="姓名搜索" class="search" @on-search="getDeletedStaff" v-model="search.realName" />
          <div class="advanced-container" @click="showAdvanced">
            <span>高级搜索</span>
            <svg class="icon" :class="{ spread: isSpread }" aria-hidden="true">
              <use xlink:href="#icon-bottom" />
            </svg>
          </div>
          <div class="advanced-board bg-shadow" :class="{ show: isSpread }">
            <Form class="form" :model="search" :label-width="40">
              <FormItem label="姓名">
                <Input v-model="search.realName" />
              </FormItem>
              <FormItem label="邮箱">
                <Input v-model="search.email" />
              </FormItem>
              <FormItem label="手机">
                <Input v-model="search.phone" />
              </FormItem>
            </Form>
            <div style="display: flex">
              <el-button
                class="search btn-shadow"
                type="danger"
                size="small"
                @click="
                  resetSearch()
                  getDeletedStaff()
                "
                >重置</el-button
              >
              <el-button class="search btn-shadow" type="primary" @click="getDeletedStaff">搜索</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-container" style="display: flex;" v-if="!isDeleteTab">
      <div id="left-tree" class="left">
        <div class="container-title" @click="clickTreeRoot">
          <Icon :size="22" type="md-git-network" style="transform:rotate(180deg)" />
          <span class="nowrap" :title="companyRoot.name" style="margin-left: 5px;flex: 1 1 auto;">{{ companyRoot.name }}</span>
          <span style="float:right;margin-right:8px;min-width: 40px">
            <Tooltip content="修改" placement="top">
              <Icon style="margin:0 4px" :size="20" type="ios-create-outline" @click.stop="clickEditTreeNode(companyRoot)" />
            </Tooltip>
            <Tooltip content="新增" placement="top">
              <Icon :size="20" type="ios-add-circle-outline" @click.stop="clickAddTreeNode(companyRoot)" />
            </Tooltip>
          </span>
        </div>
        <div style="padding: 4px;overflow-y: scroll;height: calc(100% - 70px)">
          <el-tree ref="tree" :data="treeData" highlight-current>
            <div
              slot-scope="{ node, data }"
              @click="selectTree(data)"
              style="font-size: 14px;display: flex;justify-content: space-between;width: 100%"
            >
              <Tooltip :content="node.data.title" :disabled="node.data.title.length <= 8" transfer max-width="250px">
                <div style="display: flex;align-items: center;">
                  <Icon :size="16" color="#2598E5" style="margin-right: 4px" type="md-people" />
                  <div>
                    {{ node.data.title.length > 8 ? node.data.title.substring(0, 8) + '...' : node.data.title }}
                  </div>
                </div>
              </Tooltip>
              <div>
                <Tooltip :transfer="true" content="修改" placement="top">
                  <Icon style="margin-left: 10px" :size="20" type="ios-create-outline" @click="clickEditTreeNode(data.detail, false)" />
                </Tooltip>
                <Tooltip :transfer="true" content="新增" placement="top">
                  <Icon style="margin: 0 4px" :size="18" type="ios-add-circle-outline" @click="clickAddTreeNode(data.detail, false)" />
                </Tooltip>
                <Tooltip :transfer="true" content="删除" placement="top">
                  <Icon style="margin-right: 4px" :size="18" type="ios-trash-outline" @click="showDeleteDepartmentConfirm = true" />
                </Tooltip>
              </div>
            </div>
          </el-tree>
        </div>
      </div>
      <div id="right-table">
        <div class="top-button-list container" v-if="!isDeleteTab" ref="topFilter">
          <div style="display: flex;width: 80%">
            <Button class="filter-btn top-button" icon="ios-add-circle-outline" type="primary" @click="handleAddStaff">
              新增员工
            </Button>
            <Button
              class="filter-btn top-button"
              icon="ios-trash-outline"
              type="primary"
              @click="
                showDeleteConfirm = true
                isDeleteBatch = true
              "
              :disabled="!this.checkedTableData.length"
              >删除员工
            </Button>
            <Button
              class="filter-btn top-button"
              icon="ios-create-outline"
              type="primary"
              @click="moveStaffModal = true"
              :disabled="!this.checkedTableData.length"
              >移动员工
            </Button>
            <Button
              class="filter-btn top-button"
              icon="ios-refresh"
              type="primary"
              @click="
                showResetConfirm = true
                isResetBatch = true
              "
              :disabled="!this.checkedTableData.length"
              >重置密码
            </Button>
            <Dropdown @on-click="selecMethod">
              <Button type="primary" style="padding: 0 10%">导入/导出</Button>
              <DropdownMenu slot="list">
                <DropdownItem name="in">导入</DropdownItem>
                <DropdownItem name="out">导出</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
          <div class="search-container">
            <!--            <Input search placeholder="姓名搜索" class="search" @on-search="searchMember" v-model="search.realName"/>-->
            <div class="advanced-container" @click="showAdvanced">
              <span>高级搜索</span>
              <svg class="icon" :class="{ spread: isSpread }" aria-hidden="true">
                <use xlink:href="#icon-bottom" />
              </svg>
            </div>
            <div @mouseleave="isSpread = false" class="advanced-board bg-shadow" :class="{ show: isSpread }">
              <Form class="form" :model="search" :label-width="40">
                <FormItem label="姓名">
                  <Input v-model="search.realName" />
                </FormItem>
                <FormItem label="邮箱">
                  <Input v-model="search.email" />
                </FormItem>
                <FormItem label="手机">
                  <Input v-model="search.phone" />
                </FormItem>
              </Form>
              <div style="display: flex">
                <Button
                  class="search btn-shadow"
                  type="error"
                  @click="
                    resetSearch()
                    searchMember()
                  "
                  >重置</Button
                >
                <Button class="search btn-shadow" type="primary" @click="searchMember">搜索</Button>
              </div>
            </div>
          </div>
        </div>
        <el-table
          class="yt-table"
          style="margin-top: 10px;"
          :data="staffTableData"
          height="calc(100% - 190px)"
          @selection-change="checkedRowChangeHandler"
          :loading="loading"
          ref="staffTable"
        >
          <el-table-column type="expand" width="50" align="center">
            <template slot-scope="props">
              <el-row>
                <el-col :span="2"><span>标签:</span></el-col>
                <el-col :span="22"
                  ><span>{{ props.row.tabs ? props.row.tabs.map(items => items).join('、') : '/' }}</span></el-col
                >
              </el-row>
              <el-row>
                <el-col :span="2"><span>性别:</span></el-col>
                <el-col :span="22"
                  ><span>{{ props.row.gender }}</span></el-col
                >
              </el-row>
              <el-row>
                <el-col :span="2"><span>邮箱:</span></el-col>
                <el-col :span="22"
                  ><span>{{ props.row.email }}</span></el-col
                >
              </el-row>
              <el-row>
                <el-col :span="2"><span>手机:</span></el-col>
                <el-col :span="22"
                  ><span>{{ props.row.phone }}</span></el-col
                >
              </el-row>
            </template>
          </el-table-column>
          <el-table-column width="60" type="selection" align="center" />
          <el-table-column :show-overflow-tooltip="true" label="姓名" prop="realName" />
          <el-table-column :show-overflow-tooltip="true" label="工号" prop="jobNumber" />
          <el-table-column :show-overflow-tooltip="true" label="所属部门" prop="departmentName" />
          <el-table-column :show-overflow-tooltip="true" label="岗位" prop="postName" />
          <el-table-column label="操作">
            <template slot-scope="scope">
              <span class="tool-button" @click="handleModifyStaff(scope.row)">修改</span>
              <span
                class="tool-button"
                @click="
                  editTableRow = scope.row
                  isDeleteBatch = false
                  showDeleteConfirm = true
                "
                >删除</span
              >
              <span
                class="tool-button"
                @click="
                  editTableRow = scope.row
                  isResetBatch = false
                  showResetConfirm = true
                "
                style="margin-left: 10px"
                >重置密码</span
              >
            </template>
            <!--                  <el-button size="mini" type="text" @click="handleModifyStaff(scope.row)">编辑</el-button>-->
            <!--                  <el-button size="mini" type="text" @click="editTableRow=scope.row;isDeleteBatch=false;showDeleteConfirm=true">删除</el-button>-->
            <!--                  <el-button size="mini" type="text" @click="editTableRow=scope.row;isResetBatch=false;showResetConfirm=true">重置密码</el-button>-->
          </el-table-column>
        </el-table>
        <Page
          class="yt-page"
          show-total
          show-sizer
          show-elevator
          :page-size-opts="[10, 20, 40, 100]"
          :total="staffTotal"
          :current="staffPage + 1"
          @on-change="changeStaffPage"
          @on-page-size-change="changeStaffSize"
        />
      </div>
    </div>
    <div class="bottom-container" v-if="isDeleteTab" style="flex-direction: column;">
      <el-table style="overflow-y: auto" class="container staff-table" :data="deletedTableData" @selection-change="checkedDeletedRowChangeHandler">
        <el-table-column type="expand" width="50" align="center">
          <template slot-scope="props">
            <el-row>
              <el-col :span="2"><span>标签:</span></el-col>
              <el-col :span="22"
                ><span>{{ props.row.tabs ? props.row.tabs.map(items => items).join('、') : '/' }}</span></el-col
              >
            </el-row>
            <el-row>
              <el-col :span="2"><span>性别:</span></el-col>
              <el-col :span="22"
                ><span>{{ props.row.gender === 1 ? '男' : '女' }}</span></el-col
              >
            </el-row>
            <el-row>
              <el-col :span="2"><span>邮箱:</span></el-col>
              <el-col :span="22"
                ><span>{{ props.row.email }}</span></el-col
              >
            </el-row>
            <el-row>
              <el-col :span="2"><span>手机:</span></el-col>
              <el-col :span="22"
                ><span>{{ props.row.phone }}</span></el-col
              >
            </el-row>
          </template>
        </el-table-column>
        <el-table-column width="60" type="selection" align="center" />
        <el-table-column label="姓名" prop="realName" width="150" />
        <el-table-column label="工号" prop="jobNumber" width="150" />
        <!--        <el-table-column label="标签" width="200">-->
        <!--          <template slot-scope="scope">-->
        <!--            <div class="tabsStyle">-->
        <!--              <span v-for="(t, tIndex) in scope.row.tabs" :key="tIndex">{{tIndex + 1 === scope.row.tabs.length ? t : `${t}、`}}</span>-->
        <!--            </div>-->
        <!--          </template>-->
        <!--        </el-table-column>-->
        <el-table-column label="所属部门" prop="departmentName" />
        <el-table-column label="岗位" prop="postName" />
        <!--        <el-table-column align="center" label="性别">-->
        <!--          <template slot-scope="scope"><span>{{scope.row.gender === 1 ? '男' : '女'}}</span></template>-->
        <!--        </el-table-column>-->
        <!--        <el-table-column label="邮箱" prop="email"/>-->
        <!--        <el-table-column label="手机" prop="phone"/>-->
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div class="operation">
              <el-button
                size="mini"
                type="text"
                @click="
                  editTableRow = scope.row
                  isResetBatch = false
                  showRevertConfirm = true
                "
                >恢复</el-button
              >
              <el-button
                size="mini"
                type="text"
                @click="
                  editTableRow = scope.row
                  isDeleteFinallyBatch = false
                  showDeleteFinallyConfirm = true
                "
                >彻底删除</el-button
              >
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!--      <div style="margin-top: 10px;text-align: right">-->
      <!--        <el-pagination background-->
      <!--                       @size-change="changeStaffDeSize"-->
      <!--                       @current-change="changeStaffDePage"-->
      <!--                       layout="total, prev, pager, next, sizes"-->
      <!--                       :page-size="pageParamForm.pageSize"-->
      <!--                       :current-page.sync="pageParamForm.pageNumber + 1"-->
      <!--                       :total="pageParamForm.total"-->
      <!--                       :page-sizes="[10,20,40,100]">-->
      <!--        </el-pagination>-->
      <!--      </div>-->
    </div>

    <!--    <DeleteModal v-model="showDeleteDepartmentConfirm" :title="`确认要删除部门吗？`" @on-ok="clickRemoveTreeNode">-->
    <!--       <span class="error">-->
    <!--         删除后部门下的员工也会被全部删除，你还要继续吗？-->
    <!--       </span>-->
    <!--    </DeleteModal>-->
    <ConfirmDialog
      :value="showDeleteDepartmentConfirm"
      :title="`删除部门`"
      :loading="deleteLoading"
      @on-ok="clickRemoveTreeNode"
      @on-cancel="deleteClose"
      @on-cancel-end="afterDeleteClose"
    >
      <div style="width: 219px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px"></YTIcon>
        删除后部门下的员工也会被全部删除，你还要继续吗？
      </div>
    </ConfirmDialog>
    <ConfirmDialog
      :value="showDeleteConfirm"
      :title="`删除员工`"
      :loading="deleteLoading"
      @on-ok="clickDelete"
      @on-cancel="deleteClose"
      @on-cancel-end="afterDeleteClose"
    >
      <div style="width: 219px; margin: 0 auto;">
        <YTIcon :href="'#icon-jingshi'" style="font-size: 18px"></YTIcon>
        确认要删除选中员工吗？
      </div>
    </ConfirmDialog>
    <!--    <DeleteModal v-model="showDeleteConfirm" :title="`删除员工`" @on-ok="clickDelete">-->
    <!--      <div style="width: 219px; margin: 0 auto;">-->
    <!--        确认要删除选中员工吗？-->
    <!--      </div>-->
    <!--    </DeleteModal>-->
    <DeleteModal v-model="showDeleteFinallyConfirm" :title="`确认要删除选中员工吗？`" @on-ok="clickDeleteFinally">
      <div>
        <span class="error">已选中</span><span style="color: #2598E5">{{ isDeleteFinallyBatch ? checkedDeletedTableData.length : 1 }}</span
        ><span class="error">名员工</span>
        <div><span class="error">彻底删除后则不可恢复</span><span class="error">,还要继续吗?</span></div>
      </div>
    </DeleteModal>
    <DeleteModal v-model="showRevertConfirm" :title="`确认要恢复选中员工吗？`" @on-ok="clickRestore"> </DeleteModal>
    <!--    <DeleteModal v-model="showResetConfirm" :title="`确认要重置选中员工密码吗？`" @on-ok="resetPassword" :loading="resetLoading">-->
    <!--    </DeleteModal>-->
    <el-dialog :visible="showResetConfirm" title="重置密码" class="yt-dialog yt-dialog-default" @close="cancelReset">
      <el-form ref="pwdForm" :model="resetPwd" :rules="pwdRule" label-width="142px" class="yt-form yt-dialog-form">
        <el-form-item label="请输入新密码" prop="pwd">
          <el-input v-model="resetPwd.pwd" type="password" />
        </el-form-item>
        <el-form-item label="请再次输入新密码" prop="pwd1">
          <el-input v-model="resetPwd.pwd1" type="password" />
        </el-form-item>
      </el-form>
      <div class="footer" slot="footer">
        <el-Button class="button" @click="cancelReset">取消</el-Button>
        <el-Button class="button" @click="resetPassword" :loading="resetLoading" type="primary">确定</el-Button>
      </div>
    </el-dialog>
    <StaffModal
      ref="staffModal"
      v-model="showStaffModal"
      :is-add="isStaffAdd"
      :department-id="selectedDepartmentId"
      :staff="selectedStaff"
      @on-success="handleAddStaffSuccess"
      :tabs="tabs"
    />
    <MoveStaffsModal v-model="moveStaffModal" @moveSuccess="moveS" :name="companyRoot.name" :treeData="treeData" :user-ids="userIds" />
    <DepartmentModal
      v-model="showDepartmentModal"
      :is-add="isAddDepartment"
      :department="selectedDepartment"
      :parent-id="parentId"
      @on-success="getDepartments"
    />
    <div v-if="IsImport">
      <importModal :value="IsImport" @cancel="IsImport = false" @importSuccess="importSucc" />
    </div>
  </div>
</template>
<script>
import staffApi from '../../../../api/staff'
import departmentApi from '../../../../api/department'
import DeleteModal from '../../../common/DeleteModal'
import defaultAvatar from '../../../../assets/defaultAvatar.svg'
import { Tooltip } from 'view-design'
import StaffModal from '../../../../components/manage/system/organization/StaffModal'
import DepartmentModal from '../../../../components/manage/system/organization/DepartmentModal'
import MoveStaffsModal from '../../../../components/manage/system/organization/MoveStaffsModal'
import importModal from '../../../common/importModal'
import administratorApi from '@api/administrator'
import utilApi from '../../../../util/util'
import ConfirmDialog from '../../../common/dialog/ConfirmDialog'
import YTIcon from '../../../common/YTIcon'
const defaultSearch = {
  realName: '',
  email: '',
  phone: '',
  roleId: null,
  managedDepartmentId: null,
  status: 0
}
export default {
  components: {
    ConfirmDialog,
    importModal,
    MoveStaffsModal,
    DepartmentModal,
    StaffModal,
    DeleteModal,
    YTIcon
  },
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.resetPwd.pwd1 !== '') {
          this.$refs.pwdForm.validateField('pwd1')
        }
        callback()
      }
    }
    const validatePassCheck = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.resetPwd.pwd) {
        callback(new Error('两次密码不一致，请重新输入'))
      } else {
        callback()
      }
    }
    return {
      fontLength: 0,
      pwdRule: {
        pwd: [{ validator: validatePass, trigger: 'blur' }],
        pwd1: [{ validator: validatePassCheck, trigger: 'blur' }]
      },
      resetPwd: {
        pwd: '',
        pwd1: ''
      },
      th: document.body.clientHeight - 305,
      search: Object.assign({}, defaultSearch),
      pageSize: 5,
      total: 25,
      userIds: [],
      loading: false,
      tableHeight: 200,
      countAllStaff: 0,
      showDeleteDepartmentConfirm: false,
      showDeleteConfirm: false,
      showDeleteFinallyConfirm: false,
      showRevertConfirm: false,
      showResetConfirm: false,
      deleteLoading: false,
      resetLoading: false,
      isDeleteTab: false,
      showStaffModal: false,
      moveStaffModal: false,
      showDepartmentModal: false,
      isAddDepartment: true,
      // 部门树根结点数据
      companyRoot: {},
      // 是否选中根结点
      isTreeRoot: true,
      // 部门树数据
      treeData: [],
      // 部门树选中节点
      selectedNode: {},
      // 表头
      tableHeader: [
        // 每列宽度没有实际确定
        {
          type: 'selection',
          width: 60
        },
        {
          title: '#',
          width: 55,
          render: (h, params) => {
            return h('Avatar', {
              attrs: {
                src: defaultAvatar
              }
            })
          }
        },
        {
          title: '姓名',
          // width: 90,
          key: 'realName'
        },
        {
          title: '标签',
          // width: 200,
          slot: 'tabs'
        },
        {
          title: '所属部门',
          // width: 180,
          key: 'departmentName'
        },
        {
          title: '岗位',
          // width: 150,
          key: 'postName'
        },
        {
          title: '性别',
          // width: 90,
          slot: 'gender'
        },
        {
          title: '邮箱',
          // width: 150,
          key: 'email'
        },
        {
          title: '手机',
          // width: 110,
          key: 'phone'
        },
        {
          title: '操作',
          width: 150,
          slot: 'operation',
          fixed: 'right'
        }
      ],
      // 员工表格数据
      staffTotal: 0,
      staffTableData: [],
      // 每一行独个操作数据
      editTableRow: {},
      // 是否是批量删除
      isDeleteBatch: false,
      isDeleteFinallyBatch: false,
      isRestoreBatch: false,
      isResetBatch: false,
      // 选中的多行数据
      checkedTableData: [],
      // 已删除表格数据
      deletedTableData: [],
      // 已删除表格选中的多行数据
      checkedDeletedTableData: [],
      // 员工弹窗/修改
      isStaffAdd: true,
      selectedStaff: null,
      selectedDepartment: null,
      parentId: -1,
      isSpread: false,
      tabs: [],
      IsImport: false,
      staffPage: 0,
      staffSize: 10,
      initDepartmentId: '',
      isSearch: false,
      pageParamForm: {
        pageNumber: 0,
        pageSize: 10,
        total: 0
      }
    }
  },
  created() {
    window.addEventListener('resize', this.getFontLength)
    this.getFontLength()
  },
  mounted() {
    this.getDepartments()
    this.getDeletedStaff()
    this.getAllStaff()
    this.getTabs()
    //计算表格高度使可滚动
    let el = this.$refs.topFilter
    this.tableHeight = window.innerHeight - el.offsetHeight - el.offsetTop - 65
  },
  destroyed() {
    window.removeEventListener('resize', this.getFontLength)
  },
  methods: {
    deleteClose() {
      this.showDeleteConfirm = false
      this.showDeleteDepartmentConfirm = false
    },
    afterDeleteClose() {
      this.deleteLoading = false
    },
    resetSearch() {
      this.search = Object.assign({}, defaultSearch)
    },
    moveS() {
      this.getDepartmentStaff(this.initDepartmentId)
    },
    importSucc() {
      this.getAllStaff()
      this.IsImport = false
      // this.getDepartments()
      this.getDepartmentStaff(this.initDepartmentId)
    },
    selecMethod(name) {
      if (name === 'out') {
        departmentApi.exportdepartPeo(this.initDepartmentId).then(res => {
          utilApi.exportExamPeoInfo(res, '在职员工')
        })
      } else this.importTable()
    },
    importTable() {
      this.IsImport = true
    },
    selectTree(data) {
      this.selectedNode = data
      this.isTreeRoot = false
      this.search.managedDepartmentId = data.detail.departmentId
      this.initDepartmentId = data.detail.departmentId
      this.staffPage = 0
      this.staffSize = 10
      this.isSearch = false
      this.getDepartmentStaff(data.detail.departmentId)
    },
    getTabs() {
      staffApi.getTabs().then(res => {
        this.tabs = res.res
      })
    },
    showAdvanced() {
      this.isSpread = !this.isSpread
    },
    searchMember() {
      this.loading = true
      this.isSearch = true

      // 搜索员工新接口
      const searchStaffDTO = {
        pageParamForm: {
          pageNumber: this.staffPage,
          pageSize: this.staffSize
        },
        sortCriteria: {},
        userCriteria: {
          email: this.search.email,
          phone: this.search.phone,
          realName: this.search.realName,
          status: this.search.status
        }
      }
      staffApi
        .searchStaffs(searchStaffDTO)
        .then(res => {
          this.staffTotal = res.res.total
          res.res.data.forEach(item => {
            item.gender = item.gender ? '男' : '女'
          })
          this.staffTableData = res.res.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    getAllStaff() {
      staffApi.getStaffsCount().then(res => {
        this.countAllStaff = res.res
      })
    },
    changeStaffDePage(val) {
      this.pageParamForm.pageNumber = val - 1
      this.getDeletedStaff()
    },
    changeStaffDeSize(val) {
      this.pageParamForm.pageNumber = 0
      this.pageParamForm.pageSize = val
      this.getDeletedStaff()
    },
    getDeletedStaff() {
      this.loading = true

      // 搜索删除员工新接口
      const searchStaffDTO = {
        pageParamForm: this.pageParamForm,
        pageNumber: this.pageParamForm.pageNumber,
        pageSize: this.pageParamForm.pageSize,
        email: this.search.email,
        phone: this.search.phone,
        realName: this.search.realName,
        status: this.search.status
        // sortCriteria: {},
        // userCriteria: {
        //   email: this.search.email,
        //   phone: this.search.phone,
        //   realName: this.search.realName,
        //   status: this.search.status
        // }
      }
      staffApi
        .getDeletedStaff(searchStaffDTO)
        .then(res => {
          this.deletedTableData = res.res.data
          this.pageParamForm.total = res.res.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    getDepartments() {
      this.treeData = []
      this.$store.dispatch('department/getDepartmentTree').then(res => {
        let tree = res.res
        // 默认选中的是根结点
        this.selectedNode = {
          title: tree.name,
          selected: false,
          expand: false,
          detail: tree
        }
        this.companyRoot = tree
        this.staffPage = 0
        this.staffSize = 10
        this.isSearch = false
        this.initDepartmentId = tree.departmentId
        this.getDepartmentStaff(tree.departmentId)
        this.appendTreeNode(tree, this.treeData)
      })
    },
    changeStaffPage(val) {
      this.staffPage = val - 1
      if (this.isSearch) {
        this.searchMember()
      } else {
        this.getDepartmentStaff(this.initDepartmentId)
      }
    },
    changeStaffSize(val) {
      this.staffSize = val
      this.staffPage = 0
      if (this.isSearch) {
        this.searchMember()
      } else {
        this.getDepartmentStaff(this.initDepartmentId)
      }
    },
    getDepartmentStaff(id) {
      this.loading = true
      departmentApi
        .getDepartmentStaff(id, this.staffPage, this.staffSize)
        .then(res => {
          this.staffTotal = res.res.total
          res.res.data.forEach(item => {
            item.gender = item.gender ? '男' : '女'
          })
          this.staffTableData = res.res.data
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
    handleAddStaff() {
      this.showStaffModal = true
      this.isStaffAdd = true
    },
    handleModifyStaff(row) {
      this.selectedStaff = Object.assign({}, row)
      this.selectedStaff.gender = row.gender === '男' ? 1 : 0
      this.isStaffAdd = false
      this.showStaffModal = true
      this.$refs.staffModal.getpostIds(this.isStaffAdd, this.selectedStaff)
    },
    handleAddStaffSuccess() {
      this.initDepartmentId = this.selectedDepartmentId
      if (this.isStaffAdd) {
        this.staffPage = 0
        this.staffSize = 10
      }
      this.isSearch = false
      this.getDepartmentStaff(this.selectedDepartmentId)
      this.checkedTableData = []
      this.getAllStaff()
    },
    appendTreeNode(node, data) {
      for (var i = 0, len = node.children.length; i < len; i++) {
        data.push({
          title: node.children[i].name,
          selected: false,
          expand: false,
          children: [],
          detail: node.children[i]
        })
        if (node.children[i].children.length) {
          this.appendTreeNode(node.children[i], data[data.length - 1].children)
        }
      }
    },
    clickTopTab(index) {
      if (this.isDeleteTab && index === 0) {
        this.search = {
          realName: '',
          email: '',
          phone: '',
          roleId: null,
          managedDepartmentId: null,
          status: 0
        }
        this.isSpread = false
        this.initDepartmentId = this.companyRoot.departmentId
        this.staffPage = 0
        this.staffSize = 10
        this.isSearch = false
        this.getDepartmentStaff(this.companyRoot.departmentId)
        this.isDeleteTab = false
      } else if (!this.isDeleteTab && index === 1) {
        this.search = {
          realName: '',
          email: '',
          phone: '',
          roleId: null,
          managedDepartmentId: null,
          status: 1
        }
        this.isSpread = false
        this.getDeletedStaff()
        this.isDeleteTab = true
      } else {
        return
      }
    },
    clickAddTreeNode: function(data) {
      let a = JSON.parse(JSON.stringify(data))
      this.parentId = a.departmentId
      this.isAddDepartment = true
      this.showDepartmentModal = true
    },
    clickEditTreeNode: function(data) {
      let a = JSON.parse(JSON.stringify(data))
      this.selectedDepartment = a
      this.isAddDepartment = false
      this.showDepartmentModal = true
    },
    clickTreeRoot: function() {
      this.isTreeRoot = true
      // var btns = this.$refs.tree.$el.querySelectorAll('.ivu-tree-title')
      // for (var i = 0; i < btns.length; i++) {
      //   btns[i].parentNode.style.backgroundColor = '#fff'
      //   btns[i].nextSibling.style.display = 'none'
      // }
      this.initDepartmentId = this.companyRoot.departmentId
      this.staffPage = 0
      this.staffSize = 10
      this.isSearch = false
      this.getDepartmentStaff(this.companyRoot.departmentId)
    },
    clickRemoveTreeNode: function() {
      var data = this.selectedNode
      departmentApi
        .deleteDepartment({
          departmentId: data.detail.departmentId
        })
        .then(res => {
          if (res.res) {
            this.$message.success('删除成功')
            this.getDepartments()
            this.getAllStaff()
            this.showDeleteDepartmentConfirm = false
          }
        })
    },
    checkedRowChangeHandler: function(selection) {
      this.checkedTableData = selection
      this.userIds = []
      selection.map(item => {
        this.userIds.push(item.userId)
      })
    },
    checkedDeletedRowChangeHandler: function(selection) {
      this.checkedDeletedTableData = selection
    },
    clickDelete: function() {
      var postData = {
        userIds: []
      }
      if (this.isDeleteBatch) {
        for (var i = 0, len = this.checkedTableData.length; i < len; i++) {
          if (JSON.parse(localStorage.getItem('auth'))['X-UserId'] === this.checkedTableData[i].userId + '') {
            this.$message.warning('删除的员工不能包括自身')
            return
          }
          postData.userIds.push(this.checkedTableData[i].userId)
        }
      } else {
        if (JSON.parse(localStorage.getItem('auth'))['X-UserId'] === this.editTableRow.userId + '') {
          this.$message.warning('操作的员工不能包括自身')
          return
        }
        postData.userIds.push(this.editTableRow.userId)
      }
      staffApi.deleteStaff(postData).then(res => {
        this.$message.success('删除成功')
        this.staffPage = 0
        if (this.isSearch) {
          this.searchMember()
        } else {
          this.initDepartmentId = this.isTreeRoot ? this.companyRoot.departmentId : this.selectedNode.detail.departmentId
          this.getDepartmentStaff(this.initDepartmentId)
        }
        this.checkedTableData = []
        this.showDeleteConfirm = false
        this.countAllStaff -= postData.userIds.length
        this.getDeletedStaff()
      })
    },
    clickDeleteFinally: function() {
      var postData = {
        userIds: []
      }
      if (this.isDeleteFinallyBatch) {
        for (var i = 0, len = this.checkedDeletedTableData.length; i < len; i++) {
          postData.userIds.push(this.checkedDeletedTableData[i].userId)
        }
      } else {
        if (JSON.parse(localStorage.getItem('auth'))['X-UserId'] === this.editTableRow.userId + '') {
          this.$message.warning('操作的员工不能包括自身')
          return
        }
        postData.userIds.push(this.editTableRow.userId)
      }
      // console.log(postData)
      staffApi.deleteShiftStaff(postData).then(res => {
        if (res.res === '用户不存在') {
          return
        }
        this.checkedDeletedTableData = []
        this.$message.success('删除成功')
        this.pageParamForm.pageNumber = 0
        this.getDeletedStaff()
        this.showDeleteFinallyConfirm = false
      })
    },
    cancelReset() {
      this.$refs['pwdForm'].resetFields()
      this.showResetConfirm = false
    },
    clickRestore: function() {
      var postData = {
        userIds: []
      }
      if (this.isRestoreBatch) {
        for (var i = 0, len = this.checkedDeletedTableData.length; i < len; i++) {
          postData.userIds.push(this.checkedDeletedTableData[i].userId)
        }
      } else {
        if (JSON.parse(localStorage.getItem('auth'))['X-UserId'] === this.editTableRow.userId + '') {
          this.$message.warning('操作的员工不能包括自身')
          return
        }
        postData.userIds.push(this.editTableRow.userId)
      }
      staffApi.restoreStaff(postData).then(res => {
        if (res.code === 0) {
          this.$message.success('恢复成功')
          this.pageParamForm.pageNumber = 0
          this.checkedDeletedTableData = []
          this.getDeletedStaff()
          this.countAllStaff += postData.userIds.length
          this.showRevertConfirm = false
        }
      })
    },
    resetPassword() {
      let postData = {
        userIds: [],
        password: this.resetPwd.pwd
      }
      if (this.isResetBatch) {
        for (let i = 0, len = this.checkedTableData.length; i < len; i++) {
          if (JSON.parse(localStorage.getItem('auth'))['X-UserId'] === this.checkedTableData[i].userId + '') {
            this.$message.warning('重置的员工不能包括自身')
            return
          }
          postData.userIds.push(this.checkedTableData[i].userId)
          this.resetLoading = true
        }
      } else {
        if (JSON.parse(localStorage.getItem('auth'))['X-UserId'] === this.editTableRow.userId + '') {
          this.$message.warning('操作的员工不能包括自身')
          return
        }
        postData.userIds.push(this.editTableRow.userId)
        this.resetLoading = true
      }
      this.$refs['pwdForm'].validate(valid => {
        if (valid) {
          staffApi
            .resetPwd(postData)
            .then(() => {
              this.$message.success('修改成功')
              this.$refs['pwdForm'].resetFields()
              this.getAllStaff()
              this.showResetConfirm = false
              this.resetLoading = false
            })
            .catch(() => {
              this.resetLoading = false
            })
        } else {
          this.resetLoading = false
        }
      })
    },
    getFontLength() {
      if (1200 <= window.innerWidth && window.innerWidth < 1400) {
        this.fontLength = Math.round(window.innerWidth / 400)
      } else if (window.innerWidth >= 1300) {
        this.fontLength = Math.round(window.innerWidth / 200)
      }
    }
  },
  computed: {
    selectedDepartmentId() {
      return this.isTreeRoot ? this.companyRoot.departmentId : this.selectedNode.detail.departmentId
    }
  }
}
</script>
<style lang="less" scoped>
@import '../../../../theme/variables';

.all-container {
  display: flex;
  padding: 10px 20px;
  flex-direction: column;
  height: 100%;
  /*overflow-y: auto;*/
  /*overscroll-behavior: contain;*/
}

.container {
  min-width: 300px;
  background: #fff;
  box-shadow: 0 0 5px 0 rgba(7, 27, 41, 0.1);
}

.container-title {
  width: 100%;
  height: 50px;
  background: rgba(228, 233, 239, 1);
  padding: 16px 0 16px 10px;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  color: rgba(58, 78, 100, 1);
  cursor: pointer;
  display: flex;
  align-items: center;
}

.staff-table ::v-deep {
  .operation {
    cursor: pointer;
    margin-right: 10px;
    color: @primary-color;
    transition: all @default-transition;

    svg {
      margin: 0 3px;
    }

    &:hover {
      color: @primary-hover-color;
    }
  }
}

#top-filter {
  /*box-shadow: 0 0 5px 0 rgba(7, 27, 41, 0.1);*/
  margin-bottom: 10px;
  text-align: left;
  margin-top: 10px;

  #top-tab {
    width: 100%;
    height: 40px;
    border-bottom: 2px solid #eaedf1;
    display: flex;
    padding-left: 10px;
  }

  .top-tab-item {
    margin: 6px 10px -1px 10px;
    font-weight: bold;
    cursor: pointer;
    font-size: 16px;
  }

  .top-tab-active-item {
    color: @primary-color;
    border-bottom: 3px solid @primary-color;
  }
}

::v-deep .top-button-list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding-left: 15px;

  .filter-btn {
    margin: 0 10px 0 0;
    min-width: 100px;
  }

  .ivu-input {
    border: 0;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
  }

  .ivu-input:focus {
    box-shadow: 0 1px 0 rgba(45, 140, 240, 0.2);
  }
}

.bottom-container {
  height: calc(100% - 80px);
  .ivu-table-wrapper {
    /*width: 100%;*/
  }
}

#left-tree {
  /*width: 20%;*/
  /*height: 780px;*/
  /*overflow: auto;*/
  height: 100%;
}

#right-table {
  width: calc(100% - 313px);
  /*flex: 1 1 auto;*/
  margin-left: 10px;
  display: flex;
  flex-direction: column;
}
::v-deep #tree-container {
  padding: 5px;
  /*text-align: left;*/
  .ivu-tree-title {
    width: 100%;
    padding: 3px 3px;
    display: flex;
    &:hover {
      background-color: #e4e9ef;
    }
  }

  .node-icon {
    display: none;
    svg {
      margin: 0 4px;
      color: @primary-color;
      transition: all @default-transition;

      &:hover {
        color: @primary-hover-color;
      }

      &:active {
        color: @primary-active-color;
      }
    }
  }
}
.advanced-container {
  flex: 1 0 auto;
  cursor: pointer;
  margin-left: 10px;
  font-size: 14px;
  padding-right: 20px;

  svg.icon {
    transition: all @default-transition;
    transform: rotate(0deg);
    margin-left: 8px;
  }

  svg.icon.spread {
    transform: rotate(180deg);
  }
}
.search-container {
  display: flex;
  align-items: center;
  position: relative;

  .search ::v-deep .ivu-input {
    border: 0;
    border-bottom: 1px solid #ccc;
    border-radius: 0;

    &:focus {
      box-shadow: 0 1px 0 rgba(45, 140, 240, 0.2);
    }
  }

  .advanced-container {
    flex: 1 0 auto;
    cursor: pointer;
    margin-left: 10px;
    font-size: 14px;
    padding-right: 20px;

    svg.icon {
      transition: all @default-transition;
      transform: rotate(0deg);
      margin-left: 8px;
    }

    svg.icon.spread {
      transform: rotate(180deg);
    }
  }

  .advanced-board {
    position: absolute;
    z-index: 999;
    top: 56px;
    right: 0;
    width: 340px;
    height: 0;
    overflow: hidden;
    border-right: solid 5px @primary-color;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    transition: all @default-transition;

    &.show {
      height: 260px;
    }

    .search {
      width: 100px;
      border-radius: 4px;
      &:first-child {
        margin-right: 10px;
      }
    }

    .form {
      width: 280px;
      margin-top: 20px;
    }
  }
}
::v-deep .el-tree {
  .el-tree-node__children {
    overflow: visible !important;
  }
}
.tabsStyle {
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
}
body .el-table::before {
  z-index: inherit;
}
div /deep/ i {
  font-size: 16px;
}
::v-deep .cell {
  white-space: nowrap;
}
.top-button {
  padding: 0 1.4%;
}
</style>
