<template>
  <el-dialog
    :visible="visible"
    :title="isAdd ? '新增员工' : '修改员工'"
    width="484"
    class="yt-dialog yt-dialog-default"
    @close="close"
    center
    :close-on-click-modal="false"
  >
    <!--    <div style="text-align:center;">-->
    <el-form ref="staffForm" :model="staffForm" :rules="ruleValidate" label-width="132px" class="yt-form yt-dialog-form">
      <el-form-item label="姓名" prop="realName">
        <el-input v-model="staffForm.realName" placeholder="真实姓名" />
        <div class="form-tip" v-if="!ruleValidate.realName.error"></div>
      </el-form-item>
      <el-form-item label="工号" prop="jobNumber">
        <el-input v-model="staffForm.jobNumber" maxlength="30" show-word-limit placeholder="员工工号" />
      </el-form-item>
      <el-form-item label="性别" prop="gender">
        <el-radio-group v-model="staffForm.gender">
          <el-radio :label="1">男</el-radio>
          <el-radio :label="0">女</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="标签" prop="tabs">
        <el-select v-model="staffForm.tabs" filterable allow-create multiple @on-create="handleCreate" placeholder="请选择或输入标签">
          <el-option v-for="(item, index) in tabs" :value="item.name" :key="index" v-if="item.name !== ''">
            <div style="display: flex;justify-content: space-between" @mouseenter="deleteIndex = index" @mouseleave="deleteIndex = -1">
              <span>{{ item.name }}</span>
              <i v-if="deleteIndex === index" class="el-icon-error" @click="deleteStaff(item, index)"></i>
            </div>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="邮箱" prop="email">
        <el-input v-model="staffForm.email" placeholder="请输入邮箱" />
      </el-form-item>
      <el-form-item v-if="isAdd" label="密码" prop="password">
        <el-input v-model="staffForm.password" type="password" placeholder="请输入密码" />
        <div class="form-tip" v-if="!ruleValidate.password.error">
          8-20位，字母、数字、符号中任意2种
        </div>
      </el-form-item>
      <el-form-item label="手机" prop="phone">
        <el-input v-model="staffForm.phone" placeholder="请输入手机号" />
      </el-form-item>
      <el-form-item label="岗位" prop="postId">
        <el-cascader size="small" v-model="staffForm.postId" :props="{ checkStrictly: true, expandTrigger: 'hover' }" :options="postData" />
      </el-form-item>
      <el-form-item label="简介" prop="description">
        <el-input v-model="staffForm.description" type="textarea" placeholder="请输入个人简介" />
        <div class="form-tip" v-if="!ruleValidate.description.error">
          不超过200个字
        </div>
      </el-form-item>
    </el-form>
    <!--    </div>-->
    <div class="footer" slot="footer">
      <el-Button @click="close" class="modal-btn" style="margin-right: 10px;">取消</el-Button>
      <el-Button type="primary" @click="addOrEditStaff(true)" :loading="loading" class="modal-btn">确定</el-Button>
    </div>
  </el-dialog>
</template>

<script>
import staffApi from '@api/staff'
import functionApi from '@api/functionApi'
import util from '../../../../util/util'
const defaultStaff = {
  realName: '',
  jobNumber: '',
  email: '',
  gender: 1,
  password: '',
  phone: '',
  newPhone: '',
  description: '',
  postId: [],
  tabs: []
}
export default {
  name: 'StaffModal',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Boolean,
      default: true
    },
    departmentId: {
      type: Number
    },
    staff: {
      type: Object
    },
    tabs: {
      type: Array,
      default: function() {
        return []
      }
    }
  },
  data: function() {
    const validatePhone = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('手机号码不能为空'))
      } else if (!/^1(3\d{2}|4[14-9]\d|5([0-35689]\d|7[1-79])|66\d|7[2-35-8]\d|8\d{2}|9[13589]\d)\d{7}$/.test(value)) {
        callback(new Error('请输入正确格式的手机号码'))
      } else {
        callback()
      }
    }
    const validateName = (rule, value, callback) => {
      let reg = /^(?=.*\S).+$/
      if (!value) {
        that.ruleValidate.realName.error = true
        callback(new Error('姓名不能为空'))
      } else if (!reg.test(value)) {
        that.ruleValidate.realName.error = true
        callback(new Error('姓名不能全为空格'))
      } else if (value.length > 10) {
        that.ruleValidate.realName.error = true
        callback(new Error('姓名不超过10个字'))
      } else {
        that.ruleValidate.realName.error = false
        callback()
      }
    }

    const that = this

    return {
      deleteIndex: -1,
      tags: [],
      postData: [],
      visible: this.value,
      loading: false,
      // 员工弹窗表单
      staffForm: Object.assign({}, defaultStaff),
      ruleValidate: {
        postId: {
          required: true,
          error: false,
          validator: (rule, value, callback) => {
            if (value.length === 0) {
              that.ruleValidate.realName.error = true
              callback(new Error('请选择岗位'))
            } else {
              that.ruleValidate.realName.error = false
              callback()
            }
          }
        },
        realName: {
          required: true,
          error: false,
          validator: validateName
        },
        jobNumber: {
          required: true,
          message: '工号不能为空'
        },
        email: [
          {
            required: true,
            message: '邮箱不能为空'
          },
          {
            type: 'email',
            message: '请输入正确格式的邮箱'
          }
        ],
        password: {
          required: true,
          error: false,
          validator: (rule, value, callback) => {
            if (value.length === 0) {
              that.ruleValidate.password.error = true
              callback(new Error('密码不能为空'))
            } else if (!/((?=.*\d)(?=.*\D)|(?=.*[a-zA-Z])(?=.*[^a-zA-Z]))(?!^.*[\u4E00-\u9FA5].*$)^\S{8,20}$/.test(value)) {
              that.ruleValidate.password.error = true
              callback(new Error('密码不符合规则'))
            } else {
              that.ruleValidate.password.error = false
              callback()
            }
          }
        },
        phone: [
          {
            required: true,
            message: '手机号码不能为空'
          },
          {
            validator: validatePhone
          }
        ],
        description: {
          required: false,
          error: false,
          validator: (rule, value, callback) => {
            if (value.length > 200) {
              that.ruleValidate.description.error = true
              callback(new Error('简介不超过 200 字'))
            } else {
              that.ruleValidate.description.error = false
              callback()
            }
          }
        }
      }
    }
  },
  created() {
    this.getPostData()
  },
  methods: {
    deleteStaff(data, index) {
      if (data.id) {
        staffApi.deleteTab({ tabIds: [data.id] }).then(res => {
          if (res.res) {
            this.tabs.splice(index, 1)
            this.staffForm.tabs.some((item, num) => {
              if (item === data.name) {
                this.staffForm.tabs.splice(num, 1)
              }
            })
          }
        })
      } else {
        this.tabs.splice(index, 1)
        setTimeout(() => {
          this.staffForm.tabs.some((item, num) => {
            if (item === data.name) {
              this.staffForm.tabs.splice(num, 1)
            }
          })
        }, 1000)
      }
    },
    handleCreate(val) {
      this.tabs.push({ name: val })
    },
    getPostData() {
      functionApi.getAllPostDataById(0).then(res => {
        let arr = JSON.stringify(res.res)
        this.postData = JSON.parse(
          arr
            .replace(/posts/g, 'children')
            .replace(/id/g, 'value')
            .replace(/name/g, 'label')
        )
      })
    },
    getpostIds(IsUpdate, data) {
      this.staffForm = data
      // console.log(this.postData)
      // console.log(data)
      if (IsUpdate === false && data.postId.length !== 0) {
        // 编辑
        let id = data.postId
        let a = []
        this.$nextTick(() => {
          this.staffForm.postId = util.cascSearchById(a, this.postData, this.postData, id)
          this.staffForm.postId.reverse()
        })
      }
    },
    close() {
      this.resetForm()
      this.visible = false
      this.$emit('input', false)
      this.$emit('on-cancel')
    },
    addOrEditStaff() {
      if (this.staffForm.realName.trim() === '') {
        this.$message.error('姓名不能为空')
        return
      }
      this.$refs.staffForm.validate(valid => {
        if (!valid) {
          return
        }
        this.loading = true
        const postData = this.staffForm
        postData.avatar = ''
        postData.departmentId = this.departmentId
        postData.postId = this.staffForm.postId[this.staffForm.postId.length - 1]
        const promise = this.isAdd ? staffApi.addStaff(postData) : staffApi.editStaff(postData)
        promise
          .then(res => {
            if (res.res !== true) return
            this.close()
            this.$message.success(this.isAdd ? '添加成功' : '修改成功')
            this.$emit('on-success')
          })
          .finally(() => {
            this.loading = false
            this.resetForm()
          })
      })
    },
    resetForm() {
      this.$refs.staffForm.resetFields()
    }
  },
  watch: {
    value(val) {
      this.visible = val

      if (this.isAdd) {
        this.$refs.staffForm.resetFields()
      }
    },
    staff(staff) {
      this.staffForm = staff
      this.staffForm.postId = [staff.postId]
    }
  }
}
</script>

<style lang="less" scoped>
.member-modal ::v-deep .ivu-modal {
  .ivu-modal-footer {
    .modal-btn:first-child {
      margin-right: 5px;
    }

    .modal-btn {
      .ivu-icon {
        font-size: 14px;
      }
    }
  }
}
::v-deep .ivu-select-multiple .ivu-select-item-selected:after {
  display: none;
}
::v-deep .el-dialog__title {
  font-weight: bold;
}
</style>
